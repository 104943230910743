 :root {
    --size-keywordsLg: 1rem;
    --size-keywordsMd: 0.8rem;
    --size-keywordsBase: 0.7rem;

    @media (max-width: 460px){
        .minh2xs {
            min-height: 168px;
            max-width: 350px;
        }
    }
        
    @media  (max-width:369px) {
        --size-keywordsLg: 1rem;
        --size-keywordsMd: 0.8rem;
        --size-keywordsBase: 0.6rem;
        .minh2xs {
                min-height: 158px;
            }
    }

    @media (min-width: 460px) and (max-width:1024px) {
        --size-keywordsLg: 1.5rem;
        --size-keywordsMd: 1.2rem;
        --size-keywordsBase: 1rem;
    }

     @media (min-width: 1024px) and (max-width:1280px) {
        --size-keywordsLg: 2.2rem;
        --size-keywordsMd: 1.7rem;
        --size-keywordsBase: 1.2rem;
     }

     @media (min-width: 1280px)  {
         --size-keywordsLg: 2.5rem;
         --size-keywordsMd: 2rem;
         --size-keywordsBase: 1.5rem;
     }
 }